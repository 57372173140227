<template>
  <page :footer='65'>
    <div>
      <header-bar>
        <div slot='leading' @click="$router.back()">
          <i style="font-size: 20px" class="iconfont icon-back"></i>
        </div>
      </header-bar>
      <div class="search f_flex f_a_c" style="padding-right: 12px; box-sizing: border-box">
        <search class="f_g1" shape='round' v-model='word' placeholder="搜索"></search>
        <div class="theme_c" @click="$router.back()">取消</div>
      </div>
      <mult-choose :word='word' :companyId="companyId" ref='choose' @change='changeFn'></mult-choose>
      <div style="height: 65px"></div>
    </div>

    <div class="footer f_flex f_a_c f_j_sb" slot='footer'>
      <div class="f_flex f_a_c">
        <div class="theme_c pickCount" @click='choosePanelFn'>
          已选择：
          <span v-if='pick.pick'>{{pick.pick.count}}人</span>
          <span v-else>0人</span>
        </div>
        <i class="iconfont icon-arrow-down theme_c"></i>
      </div>
      <button style="width: 114px" class="btn btn_radius theme_bg" @click='submitFn'>确定</button>
    </div>
    <popup v-model="showPopup" position="bottom" :style="{ height: '100%' }">
      <conact-content :list="pickList" @remove='removeItemFn' @close='showPopup=false'></conact-content>
    </popup>
  </page>
</template>

<script>
import multChoose from './multChoose.vue'
import {Search, Popup} from 'vant'
import conactContent from './conactContent.vue'
import groupApi from '@/api/group'

export default {
  data: ()=> ({
    word: '',
    pick: {}, // 选择后的数据 {check, pick: {pick: {item, index, originItem}, count}}
    showPopup: false,
    companyId: null
  }),
  components: {
    multChoose,
    Search,
    conactContent,
    Popup
  },
  computed: {
    pickList() {
      if (this.pick.pick && this.pick.pick.pick.length) {
        return this.pick.pick.pick
      } else {
        return []
      }
    }
  },
  methods: {
    changeFn(e) {
      this.pick = e
      this.$set(this, 'pick', e)
    },
    submitFn() {
      const {from} = this.$route.query
      if (this.pick?.pick?.count) {
        const list = this.pick.pick.pick
        const arr = []
        list.forEach(item => {
          item.forEach(ii => {
            arr.push(ii.item)
          });
        });
        
        if (from === 'create') {
          this.createGroup(arr)
        } else if (from === 'addGroupItem') {
          this.addGroupItemFn(arr)
        }
      } else {
        if (from == 'addGroupItem') {
          this.$router.back()
        } else {
          this.$toast('未选择联系人')
        }
      }
    },
    // 创建群
    createGroup(arr) {
      const obj = {
        groupName: this.$route.query.groupName,
        companyId: this.companyId,
        userList: arr
      }
      groupApi.groupCreate(obj).then(res => {
        if (res.data) {
          this.$notify({message: '创建成功', type: 'primary'})
          window.sessionStorage.removeItem('groupInfo')
          setTimeout(() => {
            this.$router.push(`/group/detail?groupId=${res.data.bizId}&companyId=${this.companyId}`)
          }, 200)
        }
      })
    },
    // 添加群成员
    addGroupItemFn(arr) {
      const {groupId, companyId} = this.$route.query
      const obj = {
        groupId: groupId,
        companyId: companyId,
        type: 1,
        userList: arr
      }
      groupApi.groupUserOperate(obj).then(res => {
        if (res.data) {
          this.$toast(res.data.msg)
          setTimeout(() => {
            this.$router.back()
          }, 20)
        }
      })
    },
    choosePanelFn() {
      if (this.pick.pick && this.pick.pick.count>0) {
        this.showPopup = true
      } else {
        this.$toast('还没有选择联系人')
      }
    },
    removeItemFn(e) {
      const conact = this.$refs.choose
      const {item, index} = e
      conact.removeItem(item, index)
    }
  },
  mounted() {
    this.companyId = this.$route.query.companyId
    // console.log(this.companyId);
  }
}
</script>

<style lang="less" scoped>
.footer {
  height: 100%;
  padding: 0 12px;
  background: #fff;
  z-index: 9;
  box-sizing: border-box;
  box-shadow: 0 0 4px rgba(100, 100, 100, 0.2);
  .pickCount {
    font-size: 16px;
  }
  .icon-arrow-down {
    font-size: 12px;
    margin-left: 8px;
    font-weight: 500;
  }
}
</style>
